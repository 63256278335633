
/***
 *Schematic view radar/Measure section.
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins } from "vue-property-decorator";
import { RadarData, SectionStateDTO } from "@/model/ModelSectionState";

import { mapGetters } from "vuex";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import Project from "@/model/Project";

import SingleDeviceHeader from "./SingleDeviceHeader.vue";
import UserAccess from "@/model/User/UserAccess";
import { calculateDeviceStatus, isTheScreenOff, getConnectionStrengthImageName } from "@/utils/DeviceHelper";
import MeasureInformationBubble from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/MeasureInformationBubble.vue";

@Component({
  name: "RadarType",
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      allSectionStates: "GetProjectSectionsStatesForMap",
      currentProject: "GetCurrentProject",
      currentThreshold: "GetCurrentThreshold"
    })
  },
  components: { SingleDeviceHeader, MeasureInformationBubble }
})
export default class RadarType extends Mixins(KmFormatted) {
  private readonly allSectionStates!: Array<SectionStateDTO>;
  readonly currentProject!: Project;
  readonly getUserAccess!: UserAccess;

  /*----------  localData  ----------*/

  importantData: RadarData = {
    name: "",
    averageSpeed: "",
    occupancy: ""
  };

  batteryClass = "low";
  cbRadioOne = false;

  deviceNetworkConnectionQualityASU = "0";
  netType = "";
  /*----------  Getters  ----------*/
  get isDefaultDirection(): boolean {
    return this.schematicInformation.direction === "DEFAULT";
  }

  get getSectionOneStatus() {
    if (this.schematicInformation.state.stateDetails && this.schematicInformation.state.id) {
      this.measurementRadarData(this.schematicInformation.state.id);
      if (this.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled) return "SUPPORT";
      else return this.schematicInformation.state.stateDetails.deviceStateOne?.status;
    } else {
      return null;
    }
  }

  /*----------  Methods  ----------*/

  measurementRadarData(sectionId: number) {
    if (!this.allSectionStates) {
      return [];
    }
    let sectionState = this.allSectionStates.find((state: SectionStateDTO) => state.sectionId === sectionId);
    if (sectionState) {
      const state = sectionState.state;
      const stateOne = state.stateDetails.deviceStateOne;
      const { worstLaneName, worstLaneOccupancy, worstLaneSpeed } = state;

      /*----------  Battery specific  ----------*/
      let batteryValueOne = stateOne.deviceBatteryState?.batteryTerminalValue || 0;
      this.batteryClass = calculateDeviceStatus(batteryValueOne);

      this.importantData = {
        name: worstLaneName,
        averageSpeed: worstLaneSpeed,
        occupancy: worstLaneOccupancy
      };

      /*----------  Network connection state  ----------*/

      this.deviceNetworkConnectionQualityASU = getConnectionStrengthImageName(stateOne.signalLevelAsu || 0).toString();
      this.netType = stateOne.netType;
      /*----------  Cb Radio Specific  ----------*/
      this.cbRadioOne = stateOne.cbRadio;

      /*----------  If the device die because of low battery  ----------*/
      const displayOneIsOff = isTheScreenOff(this.batteryClass, stateOne.status, stateOne.online);
      displayOneIsOff ? (this.cbRadioOne = false) : null;
    }
  }

  dynamicStyles(name: string) {
    if (name === "none.svg" || !name) return;
    return require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
  }

  openDisplayModalDetails(displayInformation: any) {
    if (displayInformation.vwa) {
      this.getUserAccess.Access.VIEW.project.vwa.settings = true;
    } else {
      this.getUserAccess.Access.VIEW.project.vwa.settings = false;
    }

    this.$bvModal.show("openDisplayModalDetails");
    this.$emit("openDisplayModalDetails", displayInformation);
  }

  formatSpeedWith2DecimalAndAddingKmh(speed: string) {
    return `${Number(speed).toFixed(1)} km/h`;
  }

  showRadarOnTop() {
    const radarOnTop =
      (!this.isDefaultDirection && !this.schematicInformation.radarOnLeftSide) ||
      (this.isDefaultDirection && this.schematicInformation.radarOnLeftSide);

    return this.schematicInformation.boardOne && radarOnTop;
  }

  showRadarOnButton() {
    const radarOnButton =
      (!this.isDefaultDirection && this.schematicInformation.radarOnLeftSide) ||
      (this.isDefaultDirection && !this.schematicInformation.radarOnLeftSide);

    return this.schematicInformation.boardOne && radarOnButton;
  }

  getBadgeVariant() {
    const status = this.getSectionOneStatus || "ALERT";

    const states = {
      SUPPORT: "info",
      OK: "active",
      WARNING: "pending",
      WAITING: "inactive",
      ALERT: "banned",
      INACTIVE: "inactive"
    };
    return states[status];
  }
}
