
/***
 * Display type section view in the schematic view
 * Here we find the displays, the picture to show and everything that have to do whit the display type.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [✔] Integration test.
 * @todo [✔] Update to typescript.
 */

interface SectionItem {
  haveBoardOne: string;
  displayOneType: Types | null;
  deviceOneState: keyof StateDetails;
  displayPositionOne: string;
  haveBoardTwo: string;
  displayTwoType: Types | null;
  deviceTwoState: keyof StateDetails;
  displayPositionTwo: string;
}

import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import SingleDeviceHeader from "./SingleDeviceHeader.vue";

import SectionState, { SectionStateDTO, StateDetails } from "@/model/ModelSectionState";
import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";
import { Types } from "@/interface/projectsInterface";
import { calculateDeviceStatus, isTheScreenOff, getConnectionStrengthImageName } from "@/utils/DeviceHelper";
@Component({
  name: "DisplayType",
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      allSectionStates: "GetProjectSectionsStatesForMap",
      currentProject: "GetCurrentProject"
    })
  },
  components: { SingleDeviceHeader }
})
export default class DisplayType extends Mixins(KmFormatted) {
  @Prop({ type: String, default: "left" }) sectionDirection!: string;

  /*----------  Vuex action and getters  ----------*/
  readonly getUserAccess!: UserAccess;
  private allSectionStates!: Array<SectionStateDTO>;
  readonly currentProject!: Project;

  pictureOne = "noPicture.jpg";
  pictureTwo = "noPicture.jpg";
  deviceOneBattery = "low";
  deviceTwoBattery = "low";

  deviceOneNetworkConnectionQualityASU = "0";
  deviceTwoNetworkConnectionQualityASU = "0";
  deviceOneNetType = "";
  deviceTwoNetType = "";

  cbRadioOne = false;
  cbRadioTwo = false;

  prewarmingLampOne = false;
  prewarmingLampTwo = false;

  webUrl = process.env.VUE_APP_WEB_URL;

  shouldPrewarningEnabled = false;

  @Watch("allSectionStates", { immediate: true })
  handleStateChange(state: Array<SectionStateDTO>) {
    let localState = state.find((secState: any) => secState.sectionId === this.schematicInformation.id);
    if (localState) {
      const state = new SectionState(localState.state);
      this.shouldPrewarningEnabled = this.currentProject.isFPU
        ? state.fpuSettings.prewarningEnabled
        : this.currentProject.isVSU
        ? state.vsuSettings.prewarningEnabled
        : false;

      let stateDetails = state.stateDetails;
      /*----------  Cb Radio state specific  ----------*/
      this.cbRadioOne = stateDetails.deviceStateOne.cbRadio;
      this.cbRadioTwo = stateDetails.deviceStateTwo.cbRadio;

      this.prewarmingLampOne = stateDetails.deviceStateOne.prewarningLamp;
      this.prewarmingLampTwo = stateDetails.deviceStateTwo.prewarningLamp;

      /*----------  Battery state specific  ----------*/
      this.deviceOneBattery = calculateDeviceStatus(
        stateDetails.deviceStateOne.deviceBatteryState?.batteryTerminalValue || 0
      );
      this.deviceTwoBattery = calculateDeviceStatus(
        stateDetails.deviceStateTwo.deviceBatteryState?.batteryTerminalValue || 0
      );

      /*----------  Network connection state  ----------*/
      this.deviceOneNetworkConnectionQualityASU = getConnectionStrengthImageName(
        stateDetails.deviceStateOne.signalLevelAsu || 0
      ).toString();
      this.deviceOneNetType = stateDetails.deviceStateOne.netType;
      this.deviceTwoNetworkConnectionQualityASU = getConnectionStrengthImageName(
        stateDetails.deviceStateTwo.signalLevelAsu || 0
      ).toString();
      this.deviceTwoNetType = stateDetails.deviceStateTwo.netType;

      /*----------  Picture / picture in waiting state specific  ----------*/
      let displayOneIsOnWaitingMode = stateDetails.deviceStateOne.status === "WAITING";
      let displayTwoIsOnWaitingMode = stateDetails.deviceStateTwo.status === "WAITING";
      if (displayOneIsOnWaitingMode || displayTwoIsOnWaitingMode) {
        let picture: string | undefined = "";
        picture = state.getDesiredPictureName(this.currentProject.typeSettingsName);
        /*----------  Set picture and prewarning lamp state when the section is in waiting mode   ----------*/
        if (displayOneIsOnWaitingMode) {
          this.pictureOne = picture;
          this.prewarmingLampOne = this.isPictureStauOrStgefahr(picture || "");
        }
        if (displayTwoIsOnWaitingMode) {
          this.pictureTwo = picture;
          this.prewarmingLampTwo = this.isPictureStauOrStgefahr(picture || "");
        }
      } else {
        let deviceOnePicture = stateDetails.deviceStateOne.picture;
        let deviceTwoPicture = stateDetails.deviceStateTwo.picture;

        const displayOneIsOff = isTheScreenOff(
          this.deviceOneBattery,
          stateDetails.deviceStateOne.status,
          stateDetails.deviceStateOne.online
        );
        const displayTwoIsOff = isTheScreenOff(
          this.deviceTwoBattery,
          stateDetails.deviceStateTwo?.status,
          stateDetails.deviceStateTwo?.online
        );
        displayOneIsOff ? ((this.cbRadioOne = false), (this.prewarmingLampOne = false)) : null;
        displayTwoIsOff ? ((this.cbRadioTwo = false), (this.prewarmingLampTwo = false)) : null;
        this.pictureOne = !displayOneIsOff && deviceOnePicture ? deviceOnePicture : "noPicture.jpg";
        this.pictureTwo = !displayTwoIsOff && deviceTwoPicture ? deviceTwoPicture : "noPicture.jpg";
      }
    }
  }

  /*----------  Computed properties  ----------*/
  get getSectionOneStatus() {
    if (this.schematicInformation.state.stateDetails) {
      if (this.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled) return "SUPPORT";
      else if (
        (this.schematicInformation.state.sceneSettings?.manualOverride ||
          this.schematicInformation.state.cwuSettings?.manualOverride ||
          this.schematicInformation.state.vsuSettings?.manualOverride) &&
        this.schematicInformation.state.stateDetails?.deviceStateOne?.status === "OK"
      )
        return "MANUELL";
      else return this.schematicInformation.state.stateDetails?.deviceStateOne?.status;
    } else {
      return "ALERT";
    }
  }

  get getSectionTwoStatus() {
    if (this.schematicInformation.state.stateDetails) {
      if (this.schematicInformation.state.stateDetails.deviceStateTwo.supportEnabled) return "SUPPORT";
      else if (
        (this.schematicInformation.state.sceneSettings?.manualOverride ||
          this.schematicInformation.state.cwuSettings?.manualOverride ||
          this.schematicInformation.state.vsuSettings?.manualOverride) &&
        this.schematicInformation.state.stateDetails?.deviceStateTwo?.status === "OK"
      )
        return "MANUELL";
      else return this.schematicInformation.state.stateDetails?.deviceStateTwo?.status;
    } else {
      return "ALERT";
    }
  }

  get statusClassOne() {
    return this.getClassByStatus(this.getSectionOneStatus);
  }

  get statusClassTwo() {
    return this.getClassByStatus(this.getSectionTwoStatus);
  }

  get FormattedSectionInf(): SectionItem {
    switch (this.schematicInformation.direction) {
      case "OPPOSITE":
        return {
          haveBoardOne: this.schematicInformation.boardTwo,
          displayOneType: this.schematicInformation.displayTwoType,
          deviceOneState: "deviceStateTwo",
          displayPositionOne: "deviceTwo",

          haveBoardTwo: this.schematicInformation.boardOne,
          displayTwoType: this.schematicInformation.displayOneType,
          deviceTwoState: "deviceStateOne",
          displayPositionTwo: "deviceOne"
        };

      default:
        return {
          haveBoardOne: this.schematicInformation.boardOne,
          displayOneType: this.schematicInformation.displayOneType,
          deviceOneState: "deviceStateOne",
          displayPositionOne: "deviceOne",

          haveBoardTwo: this.schematicInformation.boardTwo,
          displayTwoType: this.schematicInformation.displayTwoType,
          deviceTwoState: "deviceStateTwo",
          displayPositionTwo: "deviceTwo"
        };
    }
  }

  get isDefaultDirection(): boolean {
    return this.schematicInformation.direction === "DEFAULT";
  }
  get prewarmingOneBackground(): string {
    return this.isDefaultDirection
      ? this.getPrewarmingState(this.schematicInformation.state, this.prewarmingLampOne)
      : this.getPrewarmingState(this.schematicInformation.state, this.prewarmingLampTwo);
  }
  get prewarmingTwoBackground(): string {
    return this.isDefaultDirection
      ? this.getPrewarmingState(this.schematicInformation.state, this.prewarmingLampTwo)
      : this.getPrewarmingState(this.schematicInformation.state, this.prewarmingLampOne);
  }
  /*----------  Methods  ----------*/

  getClassByStatus(status: any) {
    switch (status) {
      case "SUPPORT":
        return "info";
      case "MANUELL":
        return "manual-mode";
      case "OK":
        return "active";
      case "WARNING":
        return "pending";
      case "WAITING":
        return "inactive";
      case "INACTIVE":
        return "inactive";
      default:
        return "banned";
    }
  }

  dynamicStyles(name: string) {
    if (name === "none.svg" || !name) return;
    return require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
  }

  getDisplaySizeWidth(displayType: any) {
    if (!displayType) return "48px";

    switch (displayType.name) {
      case "large":
        return "64px";
      case "vwa":
        return "64px";
      default:
        return "48px";
    }
  }

  openDisplayModalDetails(displayInformation: any) {
    if(displayInformation.vwa) {
      this.getUserAccess.Access.VIEW.project.vwa.settings = true;
    } else {
      this.getUserAccess.Access.VIEW.project.vwa.settings = false;
    }

    this.$bvModal.show("openDisplayModalDetails");
    this.$emit("openDisplayModalDetails", displayInformation);
  }

  getBoardSize(boardType: any) {
    if (!boardType) {
      return "48x48";
    }

    switch (boardType.name) {
      case "small":
        return "48x48";
      case "medium":
        return "48x80";
      case "large":
        return "64x96";
      case "vwa":
        return "64x144";
      default:
        return "48x48";
    }
  }

  getAllTextForTheImage(position = "TOP"): string {
    let pictureName = "";
    switch (this.schematicInformation.direction) {
      case "DEFAULT":
        pictureName = position === "TOP" ? this.pictureOne : this.pictureTwo;
        break;
      default:
        pictureName = position === "TOP" ? this.pictureTwo : this.pictureOne;
        break;
    }
    pictureName = pictureName.replaceAll(".bmp", "").replaceAll(".gif", "");

    return pictureName;
  }

  HandleImageError(event: Event | any) {
    const src = event.target?.src;
    if (src.includes(".gif")) {
      event.target.src = src.replace(".gif", ".bmp");
    }
  }

  getPrewarmingState(sectionState: SectionState, isActive: boolean): string {
    return isActive ? "#D07F16" : "";
  }

  /**
   *
   * Check if image is stau or Stgefahr
   * @param picture
   * @return Boolean
   */
  isPictureStauOrStgefahr(picture: string): boolean {
    const options = ["Stau.bmp", "Stgefahr.bmp"];
    return options.includes(picture);
  }
}
